<template>
  <Card theme="border" class="account-contract-signing" :class="{ 'account-contract-signing--active': current }">
    <template #header>
      <h1>{{ $t('purchaseSigning') }}</h1>
    </template>
    <template #default>
      <Loader :value="promise" theme="default" :message="$t('loading', { resource: $tc('contract', 1).toLowerCase() })">
        <iframe v-if="current" :src="current.signUrl" frameborder="0"></iframe>
        <div v-else class="row center wrap g-1">
          <ApplicantCard v-for="(item, index) in signers" :value="item" theme="border" :key="index">
            <template #footer>
              <ButtonComponent
                :label="$t(item.signed ? 'signed' : 'sign')"
                :theme="item.signed ? 'success' : 'primary'"
                :icon="item.signed ? 'check-circle' : ['fal', 'angle-right']"
                @click="startSigning(item)"
              />
            </template>
          </ApplicantCard>
        </div>
      </Loader>
    </template>
  </Card>
</template>

<script>
import { i18n } from '@/i18n'

import Card from '@/components/Card'
import ApplicantCard from '@/components/Applicant'
import { Initials } from '@/filters'

const meta = {
  order: 1,
  id: 'signing',
  label: i18n.tc('purchaseSigning'),
  icon: ['fal', 'file-alt'],
}

export { meta }
export default {
  props: {
    value: Object,
    labels: Object,
    validator: Object,
    isActive: Boolean,
  },
  watch: {
    signers: {
      handler(val) {
        if (val && val.length === 1) return this.startSigning(val[0])
      },
      immediate: true,
    },
    allSigned: {
      handler(val) {
        if (!val) return
        this.$emit('update:isValid', true)
        this.$emit('completed')
        return this.$emit('next')
      },
      immediate: true,
    },
  },
  data() {
    return {
      promise: null,
      current: null,
    }
  },
  computed: {
    signers() {
      let res = []
      if (!this.value) return []

      if (this.$path('value.data.applicant.signUrl')) res.push(this.value.data.applicant)
      if (this.$path('value.data.coApplicant.signUrl')) res.push(this.value.data.coApplicant)

      return res
    },
    allSigned() {
      if (!this.signers.length) return false

      return this.signers.every(s => s.signed)
    },
  },
  methods: {
    startSigning(signer) {
      if (signer.signed) return
      this.current = signer
    },
    onSignSuccess(data) {
      this.$set(this.current, 'signed', true)
      this.current = null
    },
    onSignCanceled(data) {
      this.current = null
    },
    onSignError(data) {
      this.current = null
    },
    messageHandler(event) {
      if (!['idfy.io', 'signicat.io', 'signicat.com'].some(e => event.origin.endsWith(e))) return
      let data, type, payload

      try {
        data = JSON.parse(event.data)
        type = data.type
        payload = data.payload
      } catch (err) {}

      const handlers = {
        sign_success: 'onSignSuccess',
        sign_canceled: 'onSignCanceled',
        sign_error: 'onSignError',
      }

      if (!handlers[type]) return
      return this[handlers[type]](payload)
    },
  },
  created() {
    window.addEventListener('message', this.messageHandler, false)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.messageHandler)
  },
  filters: {
    Initials,
  },
  components: {
    Card,
    ApplicantCard,
  },
}
</script>

<style lang="scss">
.account-contract-signing {
  margin: 0 auto;

  &--active {
    .card__content {
      padding: 0;
    }
  }

  iframe {
    width: 100%;
    height: 800px;
  }

  .card__header {
    min-height: 125px;
  }

  h1 {
    margin: 0;
  }
}
</style>

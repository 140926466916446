<template>
  <Loader :value="polling" theme="overlay">
    <Card class="account-contract-confirmation" theme="border">
      <template #header>
        <h1>{{ labelComp.label }}</h1>
      </template>
      <template>
        <Icon :icon="['fad', 'check-circle']" size="2x" />
        <div v-if="polling"></div>
        <div class="account-contract-confirmation__message">{{ labelComp.message }}</div>
      </template>
    </Card>
  </Loader>
</template>

<script>
import { i18n } from '@/i18n'
import { mapState } from 'vuex'
import Card from '@/components/Card'

const meta = {
  order: 2,
  id: 'confirmation',
  label: i18n.tc('documentSigned'),
  icon: ['fal', 'file-alt'],
}

export { meta }
export default {
  props: {
    value: Object,
    labels: Object,
    validator: Object,
  },
  data() {
    return {
      polling: false,
      error: false,
    }
  },
  computed: {
    ...mapState('Contract', ['item']),
    labelComp() {
      if (this.polling)
        return {
          label: this.$t('awaitingDocumentAccepted'),
          message: this.$t('awaitingDocumentAcceptedMessage'),
        }
      return {
        label: this.$t('documentAccepted'),
        message: this.error ? this.$t('documentAcceptedError') : this.$t('documentAcceptedMessage'),
      }
    },
  },
  methods: {
    async poll() {
      const statuses = [423, 412, 401]
      try {
        const baseUrl = new URL(window.location).origin.replace('sign.', '')
        let response = await fetch(`${baseUrl}/api`, {
          method: 'GET',
        })

        if (response.status === 200) {
          this.polling = false

          return (window.location.href = baseUrl)
        }
        if (statuses.includes(response.status)) {
          this.polling = true
          setTimeout(this.poll, 5000)
          return
        }
      } catch (error) {
        this.polling = false
        this.error = true
      }
    },
  },
  created() {
    this.poll()
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.account-contract-confirmation {
  text-align: center;

  .card__header {
    min-height: 125px;
  }

  &__message {
    max-width: 400px;
    line-height: 1.5;
    margin: 1rem auto 2rem;
  }

  h1 {
    margin: 0;
  }
}
</style>

<template>
  <Loader
    :value="promise"
    theme="default"
    class="account-create view"
    :message="$t('loading', { resource: this.$tc('contract').toLowerCase() }) + '...'"
  >
    <div class="container">
      <StepperComponent v-if="item && item.id" :value="steps" ref="stepper" state-handler="none">
        <template #breadcrumb="{ item, index }">
          <button type="button" disabled class="elder-stepper__breadcrumb-button">
            <span class="elder-stepper__breadcrumb-icon">
              {{ index + 1 }}
            </span>
            <span>{{ item.label }}</span>
          </button>
        </template>
      </StepperComponent>
    </div>
  </Loader>
</template>

<script>
import { StepperComponent } from 'vue-elder-stepper'
import Steps from './Steps'

export default {
  props: {
    id: String,
    accessToken: String,
  },
  watch: {
    id: {
      handler: 'fetch',
      immediate: true,
    },
  },
  data() {
    return {
      promise: null,
      item: null,
    }
  },
  computed: {
    steps() {
      let props = {
        value: this.item,
      }

      return Steps().map(raw => {
        const { icon, ...s } = raw
        s.props = {
          id: raw.id,
          ...(s.props || {}),
          ...props,
          icon,
        }
        s.listeners = {
          ...(s.listeners || {}),
        }
        return s
      })
    },
  },
  methods: {
    fetch() {
      const notFound = () => this.$router.replace({ name: 'notfound' })

      const baseUrl = new URL(window.location).origin.replace('sign.', '')

      this.promise = fetch(`${baseUrl}/api?accessToken=${this.accessToken}`)
        .then(async response => {
          if (response.status === 412) {
            const { contract } = await response.json()
            this.item = { ...contract, id: contract._id }
            if (['signed', 'accepted', 'declined'].includes(this.item.status)) this.$refs.stepper.next()
            return
          }
          if (response.status === 200) {
            return (window.location.href = baseUrl)
          }
        })
        .catch(notFound)
    },
  },
  metaInfo() {
    return {
      title:
        this.item && this.item.id
          ? this.item.title
          : this.$t('loading', { resource: this.$tc('document').toLowerCase() }) + '...',
    }
  },
  components: {
    StepperComponent,
  },
}
</script>

<style lang="scss">
.account-create {
  // background-color: var(--light-grey);
  min-height: 100vh;

  .container {
    max-width: 950px;
  }

  .elder-stepper__breadcrumb-button:disabled {
    cursor: not-allowed;
  }

  .elder-stepper__breadcrumbs {
    margin: 1.5rem 0;
    justify-content: center;

    .elder-stepper__breadcrumb {
      padding: 0.5rem 0;
    }
  }

  &__metadata {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    margin: 2rem auto 0;
  }
}
</style>
